import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"label":"Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)","single-line":"","hide-details":"","disabled":_vm.path !== undefined,"placeholder":_vm.path ? _vm.path : ''},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},model:{value:(_vm.vm.searchQuery),callback:function ($$v) {_vm.$set(_vm.vm, "searchQuery", $$v)},expression:"vm.searchQuery"}}),_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.vm.loading,"outlined":""},on:{"click":_vm.search}},[_vm._v(" Søk ")])],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.vm.lessons,"loading":_vm.vm.loading,"loading-text":"Laster inn timer...","no-data-text":_vm.vm.loaded ? 'Ingen timer funnet.' : 'Søk for å laste inn timer.'},scopedSlots:_vm._u([{key:"item.ID",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"helptext"},[_vm._v(" "+_vm._s(item.ID)+" ")]),(!item.cancelled && (!item.subtracted && !item.transferred && (!item.paymentID || item.paymentID === 'N/A')))?_c(VBtn,{attrs:{"color":"red","dark":"","small":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.updateCancellation(item, 'cancel')}}},[_vm._v(" Kanseller time ")]):(item.cancelled)?_c(VBtn,{attrs:{"color":"warning","dark":"","small":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.updateCancellation(item, 'uncancel')}}},[_vm._v(" Angre kansellering ")]):_vm._e()]}},{key:"item.customerUID",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.customer && item.customer.profile)?_c('p',[_vm._v(" "+_vm._s(item.customer.profile.firstName)+" "+_vm._s(item.customer.profile.lastName)+" ")]):_vm._e(),_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.customerUID)+" ")]),(item.customer && item.customer.account)?_c('p',{staticStyle:{"font-size":"8px"}},[_vm._v(" "+_vm._s(item.customer.account.phone)+" ")]):_vm._e()])]}},{key:"item.sellerUID",fn:function(ref){
var item = ref.item;
return [(item.seller && item.seller.profile)?_c('span',[_c('p',[_vm._v(_vm._s(item.seller.profile.firstName)+" "+_vm._s(item.seller.profile.lastName))]),_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.sellerUID)+" ")]),(item.seller.account.phone)?_c('p',{staticStyle:{"font-size":"8px"}},[_vm._v(" "+_vm._s(item.seller.account.phone)+" ")]):_vm._e()]):_vm._e()]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.unix(item.startTime).format('LLLL'))+" ")]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.unix(item.endTime).format('LLLL'))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(Math.round(item.duration / 60))+" timer")]),_c('br'),_vm._v(" "+_vm._s(item.duration)+" minutter ")]}},{key:"item.report.created",fn:function(ref){
var item = ref.item;
return [(!item.reportID)?_c('span',{staticStyle:{"color":"red"}},[_c('strong',[_vm._v("Mangler rapport!")])]):(item.report.created)?_c('span',[_vm._v(" "+_vm._s(_vm.$moment.unix(item.report.created).format('L'))),_c('br'),_vm._v(" ("+_vm._s(_vm.$moment.unix(item.report.created).diff(_vm.$moment.unix(item.endTime), 'days'))+" dager for sent) ")]):_vm._e()]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.location))]),_c('br'),_c('br'),_vm._v(" Duration: "),_c('strong',[_vm._v(_vm._s(item.duration))]),_c('br'),_vm._v(" Travel Duration: "),_c('strong',[_vm._v(_vm._s(item.travelDuration))]),(item.location === 'home' && (item.paymentID === 'N/A' || item.paymentID === '') && !item.subtracted)?_c(VBtn,{attrs:{"dark":"","small":"","color":"primary","loading":_vm.vm.loading},on:{"click":function($event){return _vm.updateTravelDuration(item)}}},[_vm._v(" Oppdater reisetid ")]):_vm._e()]}},{key:"item.subtracted",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('strong',[_vm._v(_vm._s(item.duration + (item.travelDuration || 0))+" minutter")])]),(item.subtracted > 0)?_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.subtracted).format('L')))]):(item.subtracted === -1)?_c('span',[_vm._v("Trukket manuelt")]):_c('span',[_vm._v("Ikke trukket")]),_c('br'),(item.duration && item.subtracted === 0 && (item.paymentID === 'N/A' || item.paymentID === '') && (item.travelDuration > 0 || item.location !== 'home') && item.startTime < _vm.$moment().unix())?_c(VBtn,{attrs:{"color":"warning","dark":"","small":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.subtract(item)}}},[_vm._v(" Trekk kunde nå ")]):_vm._e()]}},{key:"item.transferred",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('strong',[_vm._v(_vm._s(Math.round(item.amount / 100) || 'MANGLER')+" kr.")])]),(item.transferred > 0)?_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.transferred).format('L')))]):(item.transferred === -1)?_c('span',[_vm._v("Utbetaling avvist")]):_c('span',[_vm._v("Ikke overført")]),_c('br'),(item.amount && item.transferred === 0 && (item.paymentID === 'N/A' || item.paymentID === '') && item.startTime < _vm.$moment().unix())?_c(VBtn,{staticClass:"mb-2",attrs:{"color":"success","dark":"","small":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.transfer(item)}}},[_vm._v(" Overfør ")]):_vm._e(),(item.subtracted !== 0 && item.transferred === 0 && (item.paymentID === 'N/A' || item.paymentID === ''))?_c(VBtn,{staticClass:"mb-2",attrs:{"dark":"","x-small":"","color":"primary","loading":_vm.vm.loading},on:{"click":function($event){return _vm.updateAmount(item)}}},[_vm._v(" Oppdater sum ")]):_vm._e(),(item.subtracted !== 0 && item.transferred === 0 && (item.paymentID === 'N/A' || item.paymentID === '') && item.startTime < _vm.$moment().unix())?_c(VBtn,{staticClass:"mb-2",attrs:{"color":"error","dark":"","x-small":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.update(item, 'transferred', -1)}}},[_vm._v(" Utbetaling avvist ")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }