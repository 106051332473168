












































import LessonList from "@/components/core/LessonList.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Lessons",
  components: { LessonList },
  metaInfo() {
    return {
      title: "Admin / Timer",
    };
  },
});
