
































































































































































































import { subtractFromLesson } from "@/api/balance-service";
import { axios } from "@/configuration";
import { reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "LessonList",
  props: {
    path: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      dialogVisible: false,
      lessons: [] as Learnlink.Lesson.FullViewAdmin[],
      loaded: false,
      loading: false,
      searchQuery: "",
    });
    const headers = [
      {
        text: "lessonID",
        align: "center",
        sortable: true,
        value: "ID",
        width: "100px",
      },
      {
        text: "projectID",
        align: "center",
        sortable: true,
        value: "projectID",
        width: "100px",
      },
      {
        text: "Customer",
        align: "center",
        sortable: true,
        value: "customerUID",
        width: "100px",
      },
      {
        text: "Seller",
        align: "center",
        sortable: true,
        value: "sellerUID",
        width: "100px",
      },
      {
        text: "Start Time",
        align: "center",
        sortable: true,
        value: "startTime",
        width: "100px",
      },
      {
        text: "End Time",
        align: "center",
        sortable: true,
        value: "endTime",
        width: "100px",
      },
      {
        text: "Duration",
        align: "center",
        sortable: true,
        value: "duration",
        width: "100px",
      },
      {
        text: "Rapport sendt",
        align: "center",
        sortable: false,
        value: "report.created",
        width: "100px",
      },
      {
        text: "Location",
        align: "center",
        sortable: false,
        value: "location",
        width: "100px",
      },
      {
        text: "Kunde trukket",
        align: "center",
        sortable: true,
        value: "subtracted",
        width: "100px",
      },
      {
        text: "Overført til PL",
        align: "center",
        sortable: true,
        value: "transferred",
        width: "100px",
      },
      {
        text: "paymentID",
        align: "center",
        sortable: false,
        value: "paymentID",
        width: "100px",
      },
    ];

    async function search() {
      vm.loading = true;

      const lessonRequest = await axios.get("/admin/lessons/" + (props.path ? props.path : (vm.searchQuery ? vm.searchQuery : "list")));

      vm.lessons = lessonRequest.data;
      vm.loading = false;
      vm.loaded = true;
    }

    async function subtract(lesson: Learnlink.Lesson.FullViewAdmin) {
      const index = vm.lessons.indexOf(lesson);

      vm.loading = true;
      Vue.set(vm.lessons, index, lesson);

      const employeeInitials = store.state.profile.firstName.charAt(0) + store.state.profile.lastName.charAt(0);
      const employeeUID = store.state.profile.uid;

      await subtractFromLesson(lesson.ID, employeeInitials, employeeUID);

      const updatedLesson = await axios.get(`/lessons/${lesson.ID}`);

      Object.assign(vm.lessons[index], updatedLesson.data);

      lesson = vm.lessons[index];
      vm.loading = false;
      Vue.set(vm.lessons, index, lesson);
    }

    async function transfer(lesson: Learnlink.Lesson.FullViewAdmin) {
      const index = vm.lessons.indexOf(lesson);

      vm.loading = true;
      Vue.set(vm.lessons, index, lesson);

      await axios.post("/stripe/transfers/" + lesson.ID);

      const updatedLesson = await axios.get("/lessons/" + lesson.ID);

      Object.assign(vm.lessons[index], updatedLesson.data);

      lesson = vm.lessons[index];
      vm.loading = false;
      Vue.set(vm.lessons, index, lesson);
    }

    async function updateAmount(lesson: Learnlink.Lesson.FullViewAdmin) {
      const index = vm.lessons.indexOf(lesson);
      vm.loading = true;
      Vue.set(vm.lessons, index, lesson);

      const lessonRes = await axios.put(`/admin/lessons/updateAmount/${lesson.ID}`);
      store.dispatch("displayAlert", {
        color: "green",
        message: "Oppdatert",
      });

      Object.assign(vm.lessons[index], lessonRes.data);

      lesson = vm.lessons[index];
      vm.loading = false;
      Vue.set(vm.lessons, index, lesson);
    }

    async function update(lesson: Learnlink.Lesson.FullViewAdmin, field: string, value: unknown) {
      const index = vm.lessons.indexOf(lesson);
      vm.loading = true;
      Vue.set(vm.lessons, index, lesson);

      const updateObject = {
        ID: lesson.ID,
        [field]: value,
      } as Learnlink.Lesson.Update;

      const lessonRes = await axios.put("/lessons/" + lesson.ID, updateObject);
      Object.assign(vm.lessons[index], lessonRes.data);

      lesson = vm.lessons[index];
      vm.loading = false;
      Vue.set(vm.lessons, index, lesson);
    }

    async function updateCancellation(lesson: Learnlink.Lesson.FullViewAdmin, type: string) {
      const index = vm.lessons.indexOf(lesson);

      vm.loading = true;
      Vue.set(vm.lessons, index, lesson);

      const lessonRes = await axios.put("/lessons/" + lesson.ID + "/" + type, { ID: lesson.ID });
      Object.assign(vm.lessons[index], lessonRes.data);

      lesson = vm.lessons[index];
      vm.loading = false;
      Vue.set(vm.lessons, index, lesson);
    }

    async function updateTravelDuration(lesson: Learnlink.Lesson.FullViewAdmin) {
      const index = vm.lessons.indexOf(lesson);

      vm.loading = true;
      Vue.set(vm.lessons, index, lesson);

      const lessonRes = await axios.put(`/lessons/${lesson.ID}/travelDuration`);
      Object.assign(vm.lessons[index], lessonRes.data);

      lesson = vm.lessons[index];
      vm.loading = false;
      Vue.set(vm.lessons, index, lesson);
    }

    return {
      headers,
      search,
      subtract,
      transfer,
      update,
      updateAmount,
      updateCancellation,
      updateTravelDuration,
      vm,
    };
  },
});
